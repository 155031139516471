// External Dependencies
import PropTypes from 'prop-types';
import React from 'react';

// Component Definition
const CurveSection = ({
  children,
  id,
}) => {
  return (
    <section className="curve-section" id={id}>
      <svg className="curve-flip" preserveAspectRatio="none" viewBox="0 0 1440 96" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m0 96 80-5.3C160 85 320 75 480 64s320-21 480-21.3c160 .3 320 10.3 400 16l80 5.3V0H0v96Z" /></svg>

      <div className="children-wrapper">
        {children}
      </div>

      <svg className="curve" preserveAspectRatio="none" viewBox="0 0 1440 96" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m0 96 80-5.3C160 85 320 75 480 64s320-21 480-21.3c160 .3 320 10.3 400 16l80 5.3V0H0v96Z" /></svg>
    </section>
  );
};

CurveSection.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
};

export default CurveSection;
