// External Dependencies
import { Link } from 'gatsby';
import React from 'react';
import Typography from '@mui/material/Typography';

// Internal Dependencies
import CurveSection from '../components/shared/CurveSection';
import HeaderGeneric from '../components/HeaderGeneric';
import Layout from '../components/layout';
import Meta from '../components/Meta';
import TadaSvg from '../components/TadaSvg';

// Component Definition
const PromotionSuccess = () => (
  <Layout hideFooter>
    <Meta title="Switch Promotion Success" />

    <HeaderGeneric
      title="Thank you for entering our promotion"
    />

    <CurveSection>
      <div className="panel">
        <TadaSvg width={200} />

        <Typography sx={{ marginTop: 4 }} variant="h5">
          Our team will contact you soon with more details.
        </Typography>
      </div>
    </CurveSection>

    <div className="center">
      <Link
        className="button special-2"
        to="/"
      >
        Home
      </Link>
    </div>

  </Layout>
);

export default PromotionSuccess;
